.mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    
    width: 100%;
    height: 75%;
}






























.get-in-button > p{
font-size: 16px;
text-align: center;
color: black;}


.get-in-touch {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .title {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 3.2em;
    line-height: 48px;
    padding-bottom: 24px;
       color: #5543ca;
      background: #5543ca;
      background: -moz-linear-gradient(left,#f4524d  0%,#5543ca 100%) !important;
      background: -webkit-linear-gradient(left,#f4524d  0%,#5543ca 100%) !important;
      background: linear-gradient(to right,#f4524d  0%,#5543ca  100%) !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
  }
  .contact-form {
    width: 80%;
  }
  .contact-form .form-field {
    
    position: relative;
    margin: 32px 0;
  }
  .contact-form .input-text {
    display: block;
    width: 100%;
    height: 36px;
    border-width: 0 0 2px 0;
    border-color: #000000;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
  .contact-form .input-text:focus {
    outline: none;
  }
  .contact-form .input-text:focus + .label,
  .contact-form .input-text.not-empty + .label {
    -webkit-transform: translateY(-35px);
            transform: translateY(-35px);
            font-weight: 700;
  }
  .contact-form .label {
    position: absolute;
    left: 20px;
    bottom: 11px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #000000;
    cursor: text;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, 
    -webkit-transform .2s ease-in-out;
  }
  .contact-form .submit-btn {
    display: inline-block;
    background-color: #000;
     background-image: linear-gradient(125deg,#a72879,#064497);
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    width:200px;
    cursor: pointer;
  }
  



.cat_container {
    margin: 0;
    overflow: hidden;
    background: #FFF;
    position: relative;
    height: 300px;
}
.cat_container .cat {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 188px;
    height: 260px;
    margin-left: -99px;
    margin-top: -130px;
    color: #000000;
}
.cat_container .cat .paw {
    position: absolute;
    top: 176px;
    left: 88px;
    width: 20px;
    height: 80px;
    background: currentcolor;
    border-radius: 20px/0 0 80px 40px;
    transform: rotate(10deg);
    animation: paw_l 0.45s infinite linear;
}
.cat_container .cat .paw:after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: -4px;
    width: 20px;
    height: 26px;
    background: currentcolor;
    border-radius: 50%;
    transform: rotate(24deg);
}
.cat_container .cat .paw:nth-child(2) {
    left: 130px;
    animation: paw_r 0.45s infinite linear;
}
.cat_container .cat .shake {
    position: absolute;
    width: 100%;
    top: 0;
    animation: shake 0.45s infinite linear;
}
.cat_container .cat:before {
    position: absolute;
    content: "";
    top: 100%;
    left: 50%;
    width: 130px;
    height: 30px;
    margin-left: -50px;
    margin-top: -20px;
    background: #616161;
    opacity: 0.4;
    border-radius: 50%;
    animation: shadow 0.45s infinite linear;
    animation-delay: 0.225s;
}
.cat_container .cat:after {
    position: absolute;
    content: "";
    top: 100%;
    left: 100%;
    width: 15px;
    height: 10px;
    margin-left: -30px;
    margin-top: -10px;
    background: #616161;
    opacity: 0.3;
    border-radius: 50%;
    box-shadow: 50px 0 #616161, 100px 0 #616161, 150px 0 #616161, 200px 0 #616161, 250px 0 #616161, 300px 0 #616161;
    animation: track 0.225s infinite linear;
}
.cat_container .cat .tail {
    position: absolute;
    content: "";
    top: 0;
    right: -4px;
    width: 160px;
    height: 150px;
    border: 20px solid;
    border-color: currentcolor transparent transparent currentcolor;
    transform: rotate(45deg);
    border-radius: 120px/106px 120px 0 120px;
    box-sizing: border-box;
    animation: tail 0.45s infinite linear;
}
.cat_container .cat .tail:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: currentcolor;
    border-radius: 50%;
    bottom: 0;
    box-shadow: 2px 4px currentcolor, 2px 7px currentcolor, 2px 10px currentcolor, 1px 14px currentcolor, -1px 18px currentcolor, -5px 22px currentcolor, -10px 25px currentcolor, -15px 28px currentcolor, -20px 30px currentcolor;
}
.cat_container .cat .main {
    position: absolute;
    top: 14px;
    right: 0;
    width: 144px;
    height: 216px;
    color: currentcolor;
}
.cat_container .cat .main .head {
    position: absolute;
    bottom: -10px;
    left: 20px;
    width: 104px;
    height: 180px;
    background: currentcolor;
    border-radius: 55px/100px 100px 65px 65px;
    transform: rotate(40deg);
}
.cat_container .cat .main .body {
    position: absolute;
    right: 0;
    width: 130px;
    height: 180px;
    background: currentcolor;
    border-radius: 65px/70px 70px 100px 100px;
}
.cat_container .cat .main .body .leg {
    position: absolute;
    right: -10px;
    top: 20px;
    width: 50px;
    height: 116px;
    background: currentcolor;
    border-radius: 25px/0 60px 50px 0;
}
.cat_container .cat .main .body .leg:before {
    position: absolute;
    content: "";
    bottom: -4px;
    right: 0;
    width: 20px;
    height: 40px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-left-color: #616161;
    -webkit-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
    transform: rotate(6deg);
}
.cat_container .cat .main .body .leg:after {
    position: absolute;
    content: "";
    left: 3px;
    bottom: 35px;
    width: 76px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #616161;
    transform: rotate(65deg);
}
.cat_container .cat .main .face {
    position: absolute;
    bottom: 0;
    width: 76px;
    height: 80px;
}
.cat_container .cat .main .face .nose {
    position: absolute;
    bottom: 8px;
    left: 50%;
    width: 18px;
    height: 9px;
    margin-left: -10px;
    background: #9c1b4d;
    border-radius: 20px/10px 10px 20px 20px;
}
.cat_container .cat .main .face .mustache_cont {
    position: absolute;
    bottom: 5px;
    width: 20px;
    height: 10px;
    animation: mustache_l 0.45s infinite linear;
}
.cat_container .cat .main .face .mustache_cont:nth-child(2) {
    right: 0;
    animation: mustache_r 0.45s infinite linear;
}
.cat_container .cat .main .face .mustache {
    position: absolute;
    top: 0;
    width: 100%;
    border: 1px solid #616161;
    transform-origin: 100% 0;
    transform: rotate(-10deg);
}
.cat_container .cat .main .face .mustache:last-child {
    top: 6px;
    transform: rotate(-20deg);
}
.cat_container .cat .main .face .eye {
    position: absolute;
    top: 28px;
    left: -8px;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 3px solid #000;
    border-radius: 50%;
    box-sizing: border-box;
}
.cat_container .cat .main .face .eye:nth-child(5) {
    top: 26px;
    left: 36px;
}
.cat_container .cat .main .face .eye:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    right: 1px;
    top: 4px;
    background: #000;
    border-radius: 50%;
    animation: eye 0.45s infinite linear;
}
.cat_container .cat .main .face .brow_cont, 
.cat_container .cat .main .face .brow_cont {
    position: absolute;
    top: 14px;
    left: 10px;
    width: 10px;
    height: 10px;
}
.cat_container .cat .main .face .brow_cont:nth-child(7), 
.cat_container .cat .main .face .brow_cont:nth-child(7) {
    transform: rotateY(180deg) rotateZ(0);
    left: 38px;
}
.cat_container .cat .main .face .brow {
    position: absolute;
    top: 20%;
    height: 60%;
    border: 1px solid #616161;
    transform-origin: 100% 0;
    transform: rotate(10deg);
}
.cat_container .cat .main .face .brow:last-child {
    top: 0;
    height: 100%;
    left: 6px;
    transform: rotate(3deg);
}
.cat_container .cat .main .face .ear_l {
    position: absolute;
    top: -17px;
    width: 20px;
    height: 30px;
    background: currentcolor;
    border-radius: 20px/55px 55px 0 0;
    transform-origin: 50% 100%;
    overflow: hidden;
    transform: rotate(-20deg);
    animation: ear_l 0.45s infinite linear;
}
.cat_container .cat .main .face .ear_l .inner {
    position: absolute;
    top: 5px;
    left: 50%;
    width: 6px;
    height: 14px;
    margin-left: -4px;
    background: #616161;
    border-radius: 7px/20px 20px 0 0;
}
.cat_container .cat .main .face .ear_r {
    position: absolute;
    right: 0;
    margin-top: -22px;
    width: 36px;
    height: 30px;
    transform-origin: 50% 100%;
    overflow: hidden;
    transform: rotate(20deg);
    animation: ear_r 0.45s infinite linear;
}
.cat_container .cat .main .face .ear_r .outer {
    position: absolute;
    width: 30px;
    height: 200%;
    border: 3px solid #616161;
    border-radius: 20px/55px 55px 0 0;
}
.cat_container .cat .main .face .ear_r .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 26px;
    margin-left: -6px;
    background: #616161;
    border-radius: 50%;
}
@keyframes shake {
    50% {
        top: 6px;
    }
}
@keyframes shadow {
    50% {
        width: 110px;
        margin-left: -40px;
    }
}
@keyframes tail {
    50% {
        transform: rotate(38deg);
    }
}
@keyframes track {
    to {
        margin-left: 20px;
    }
}
@keyframes eye {
    50% {
        top: 2px;
    }
}
@keyframes mustache_l {
    50% {
        transform: rotate(-10deg);
    }
}
@keyframes mustache_r {
    0%, 100% {
        transform: rotateY(180deg) rotateZ(0);
    }
    50% {
        transform: rotateY(180deg) rotateZ(-10deg);
    }
}
@keyframes ear_l {
    50% {
        transform: rotate(-30deg);
    }
}
@keyframes ear_r {
    50% {
        transform: rotate(30deg);
    }
}
@keyframes paw_l {
    50% {
        left: 130px;
        top: 176px;
    }
    70% {
        top: 160px;
    }
}
@keyframes paw_r {
    25% {
        top: 160px;
    }
    50% {
        left: 88px;
        top: 176px;
    }
}